
body{
  font-family: graphik, helvetica, sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* E-commerce */

.header-top{
  background: #362465;
  padding: 16px;
}
.header-icons{
  padding-top: 10px;
}
.below-carousel{
  background-color: #36246535;
  padding: 26px;
}
.carousel-item{
  height: 614px;
}
.cart-summary-title{
  font-weight: 400 !important;
  line-height: 1.4em !important;
  color: #6a5b5c !important;
  font-size: 20px !important;
}
.cart-block{
  padding: 16px;
}
.cart-summary-section{
  padding: 1em 25px !important;
}
.cart-heading{
  font-weight: 400;
  font-style: normal;
  line-height: 1.4em;
  color: #6a5b5c;
}
.pe-0{
  padding-left: 0px !important;
}
.cart-items-heading{
  padding: 1em 25px;
}
.cart-heading{
  font-size: 20px;
}
.product-name{
  line-height: 1.2em;
    font-weight: 500;
    color: #000;
    text-decoration: none;
}
.item-weight{
  margin: 0.25em 0 0.4em;
  font-size: 90%;
  line-height: 1em;
  opacity: 0.75;
}
.item-price{
  margin-bottom: 0.5em;
  color: #2c2528;
}
.current-price{
  font-weight: 500;
}
.btn-primary{
  background-color: #362465 !important;
  color: #fff;
  border: 1px solid #362465 !important;
}
.product-img{
  padding-right: 20px;
}
.cart-item-details-section{
  padding: 1em 25px !important;
}
.img-promo{
  width: 100%;
}
.img-outer{
  transform: scale(1) translate3d(0, 0, 0);
  transition: transform 0.7s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}
.below-carousel-head{
  font-weight: 400 !important;
}
.product-block{
  padding: 16px;
  border: 1px solid #e2e2e2;
}
.item-img{
  width: 162px;
}
.product-block-weight-value{
  margin: 6px 0 8px;
    opacity: 0.75;
    font-size: 90%;
}
.product-block-title-price .title{
  display: block;
  color: inherit;
  text-decoration: none;
  padding-top: 0.1em !important;
  margin: 0 0 0.2em !important;
  font-weight: 500 !important;
}
.second-sec-heading{
  font-size: 25px !important;
  color: #6a5b5c;
  font-weight: 400 !important;
}
.product-detail-img{
  width: 589px;
}

.shop{
  background: #f4f2e9;
}
.slide-img{
  width: 175px;
  border-radius: 50%;
}
.react-multi-carousel-list ul li {
  width: 174px !important;
}
.shop-carousel.react-multi-carousel-list ul li{
  width: 266px !important;
}
.custom-slider div{
  display: grid;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}
.position-sticky {
  position: sticky;
  top: 0;
  /* justify-content: flex-start; */
  height: 100vh;
}
.productdetail-name{
  font-size: 1.5rem !important;
  margin-top: 88px !important;
}
.product-weight{
  color: #2c2528;
  font-size: 16px !important;
  font-weight: 300 !important;
    font-style: normal !important;
    padding: 12px 0px;
}
.product-price{
  font-size: 1.6em !important;
    color: #2c2528;
    font-weight: 400 !important;
    padding-bottom: 12px;
}
.add-btn{
  margin-top: 24px !important;
}
.btn-group-cart{
  display: flex;
}
.cart-btn{
  width: 100%;
  background: #ffffff !important;
  color: #362465 !important;
  border: 2px solid #362465 !important;
}
.product-availability-tagline{
  display: flex;
  margin-top: 12px;
  padding-bottom: 12px;
}
.checkicon{
  color: #362465;
}
.product-availability-tagline div p{
  padding-left: 12px;
}
.description{
  /* font-weight: 300 !important; */
  font-style: normal;
  font-size: 16px !important;
  text-align: justify;
  padding-bottom: 12px;
}
.bg-allproducts{
  background: url(./images//all-products-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-block{
  padding: 76px;
}
.bg-allproducts h2{
  font-size: 39px !important;
  color: #ffffff !important;
}
.filter-heading{
  display: flex;
  align-items: center;
}
.filter-top-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-label{
  font-size: 1em !important;
}
.filter-icon1{
  background-color: rgb(247, 247, 247);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-block-scroll{
  display: flex;
  justify-content: space-between;
}
.filter-dropdown .MuiFormControl-root{
  min-width: 150px;
  /* height: 40px; */
    background-color: rgb(247, 247, 247);
    border-radius: 20px;
}
.filter-dropdown label{
  top: -12px;
}
.filter-dropdown .MuiFormControl-root .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  height: 40px;
  border: none !important;
  border-radius: 20px;
}
.title{
  text-decoration: none;
  color: #282c34;
  text-transform: capitalize !important;
}

.merchant-name{
  text-decoration: none;
  color: #362465;
  font-weight: 500;
}
.merchant-name-head{
  font-family: nantes, georgia, serif !important;
  font-weight: 400 !important;
  font-size: 30px !important;
}
.about-merchant{
  /* font-weight: 200 !important; */
    font-size: 18px !important;
    line-height: 26px;
    color: rgb(117, 117, 117);
    padding: 12px 0px;
}
.category-name-head{
  font-size: 22px !important;
  font-family: nantes, georgia, serif !important;
}
.catagory-about{
  font-size: 14px;
  font-family: graphik, helvetica, sans-serif;
  line-height: 20px;
}
.mini-slide-img{
  width: 72px;
  border-radius: 50%;
}
.pcode{
  padding: 0.475rem 0.75rem;
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.flex {
  display: flex;
  align-items: center;
  flex-direction: row !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
.custom-ml{
  margin-left: -1px;
  border-top-right-radius: 2.5rem !important;
  border-bottom-right-radius: 2.5rem !important;
  /* background: #fff; */
}
.ml-20 {
  margin-left: 1.3rem !important;
}
.fixed-height{
  height: 39px;
}
.viewmore-dpdown-detail{
  height: 40px;
  width: 358px;
}
.select{
  height: 40px;
}
.p-button{
  padding: 0.475rem 0.75rem;
  color: #fff !important;
  box-shadow: unset !important;
  background-color: #b02a37 !important;
  border-color: #a52834 !important;
}
.product-detail-accordion {
  background: none !important;
  box-shadow: none !important;
  margin-bottom: 16px;
}
.accordian-header svg {
  font-size: 2.5rem !important;
  fill: #9568ff !important;
}
.accordian-header .MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
}

/* Swiper-Css */
.mySwiper2 {
  height: 80%;
  width: 100%;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 360px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}

.custom-swiper-slider .mySwiper .swiper-slide {
  opacity: 0.4;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next {
  color: #726a6a !important;
}
.swiper-button-prev {
  color: #726a6a !important;
}

/* Cart CSS */

.cart-heading{
  font-size: 22px !important;
  font-family: nantes, georgia, serif !important;
}
.content-centered{
  padding: 12px 100px;
}
.shop-name{
  font-size: 18px !important;
  font-family: graphik, helvetica, sans-serif !important;
  font-weight: 600 !important;
  color: rgb(51, 51, 51);
}
.shop-block{
  display: flex;
  align-items: center;
}
.items-block{
  border: 1px solid rgb(223, 224, 225);
  padding: 16px;
}
.item-name{
  font-size: 14px !important;
  font-family: graphik, helvetica, sans-serif !important;
  font-weight: 600 !important;
  color: rgb(51, 51, 51);
}
.item-img-box{
  width: 130px !important;
}
.item-card{
  display: flex;
  align-items: flex-start;
}
.item-price{
  font-size: 14px !important;
  font-family: graphik, helvetica, sans-serif !important;
  color: rgb(51, 51, 51);
}
.fixed-top-item-card{
  margin-top: 6%;
}
.dpdown-item-card{
  width: 172px !important;
}
.item-total{
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: graphik, helvetica, sans-serif !important;
  color: rgb(51, 51, 51);
}

/* Cart-CSS End*/
.mb-15{
  margin-bottom: 1.5rem;
}
.bgm-gray{
  /* background-color: #F0F0F0 !important; */
  margin-bottom: 20px;
}
.bold-data table tr:nth-last-child(-n+4) td{
  font-weight: bold !important;
}
.bold-data table tr:last-child td{
  font-size: 16px;border-color: #362465 !important;
}
.wx-100 {
  width: 130px;
}
.retail-btn{
  background: #fff !important;
  border-color: #362465 !important;
  color: #362465 !important;
  font-size: 12px !important;
}
.retail-btn:hover{
  background-color: #362465 !important;
  color: #fff !important;
}
.address-line{
  font-size: 14px !important;
}
.address-block-heading{
  font-weight: bold !important;
  font-size: 16px !important;
}
.details-heading{
  font-weight: bold !important;
  font-size: 14px !important;
}
.details-value{
  font-size: 14px !important;
}
.merchant-name-heading{
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: graphik, helvetica, sans-serif !important;
}
.merchant-address{
  font-size: 15px !important;
}
.w-400 {
  max-width: 400px;
  width: 100%;
}
.view-more-content {
  /* max-width: 1000px;
  width: 100%; */
  transform: unset !important;
  border: unset !important;
  border-radius: 10px !important;
  padding: unset !important;
  padding-bottom: 20px !important;
  position: unset !important;
  margin: 10px auto !important;
  top: unset !important;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-more-content .modal-header h5 {
  font-size: 1rem !important;
  font-family: "raleway-bold", sans-serif;
  font-weight: 700;
  color: #48494D;
}
.p-15 {
  padding: 15px 15px !important;
}
.m-15 {
  margin: 15px !important;
}
.font-bold {
  font-weight: 600 !important;
  font-size: 0.8rem !important;
}
.view-more-content label {
  display: block;
  font-size: 13px;
  color: #666;
}
.w-700 {
  max-width: 700px !important;
  width: 100%;
}
.view-more-content input {
  border-radius: 2.5rem;
  color: #99abba;
  font-size: 0.785rem;
  padding: 0.375rem 0.75rem !important;
  font-weight: 400;
}
.float-right {
  float: right;
}
.cs-width{
  width: 100% !important;
}
.jobshero{
  background-image: url(../src/images/jobshero.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
}
.jobs-apply-block{
  padding: 0px 160px;
}
.textarea-cs{
  width: 100%;
  height: 80px !important;
}
@media (min-width:375px) and (max-width:415px){
  .banner-block{
    padding: 12px;
  }
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.filter-block-scroll{
  flex-direction: column;
}
.filter-dropdown .MuiFormControl-root .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  width: 100% !important;
}
.filter-dropdown .MuiFormControl-root{
  width: 100%;
}
.mobile-width{
  width: 100%;
}
.filter-icon1{
  margin-bottom: 12px;
}
.filter-dropdown{
  margin-bottom: 12px;
}
.react-multi-carousel-track{
  flex-direction: column !important;
}
.css-heg063-MuiTabs-flexContainer{
  flex-direction: column;
}
} 